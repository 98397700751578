import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
console.log("company="+process.env.REACT_APP_COMPANY)
let company =  "generic";
if(process.env.REACT_APP_COMPANY){
    company = process.env.REACT_APP_COMPANY;
}

//import App from `./${company}/App`;

const App = React.lazy(() => 
    import(`./${company}/App`));
//import App from './sportsbook/App';
//import App from './fanzone/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />

);

